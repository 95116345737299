import { bookingStates, getBookingState } from "./belegungsPlan";

const bookingStateToCssClassMap = {
    [bookingStates.NONE]: 'DayWithStatusFree',
    [bookingStates.MORNING]: 'FromBookedToNeutral',
    [bookingStates.EVENING]: 'FromNeutralToBooked',
    [bookingStates.MORNING_AND_EVENING]: 'DayWithStatusBooked FromBookedToBooked',
    [bookingStates.FULL]: 'DayWithStatusBooked',
}

export default function Week({week, days, month, year}) {
    const dayCells = Object.entries(days).map(([index, day]) => {
        let classes;
        if (day) {
            const date = new Date(`${year}-${month}-${day} 00:00:00 UTC`);
            classes = bookingStateToCssClassMap[getBookingState(date)] || '';
            classes += ' DayWithStatus';
            const dayOfWeek = date.getUTCDay();
            if (dayOfWeek === 6) {
                classes += ' Saturday';
            } else if (dayOfWeek === 0) {
                classes += ' Sunday';
            }
        } else {
            classes = 'DayEmpty';
        }

        return <td key={`${week}-${index}`} className={classes}>{day ?? ''}</td>
    });

    return <tr>
        <td className="WeekOfYear">{week}</td>
        {dayCells}
    </tr>
}

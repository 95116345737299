import Week from "./Week";
import { getDaysArray } from "./DateHelper";

export default function MonthSheet({year, month}) {
    const weeks = Object.entries(getDaysArray(year, month)).map(([weekOfYear, daysOfMonth]) =>
        <Week key={weekOfYear} week={weekOfYear} days={daysOfMonth} month={month} year={year}></Week>
    )

    const monthName = (new Date(Date.UTC(year, month - 1, 1))).toLocaleString('default', { month: 'long' });

    return <div className="CalendarMonthSheet CalendarMonthSheet1">
        <table className="CalendarMonth notranslate" translate="no">
            <tbody>
            <tr>
                <th></th>
                <th className="CalendarMonthCaption" colSpan="7">
                    <span className="CalendarMonthCaption">{monthName} {year}</span>
                </th>
            </tr>
            <tr>
                <th className="CalendarDay">KW</th>
                <th className="CalendarDay">Mo</th>
                <th className="CalendarDay">Di</th>
                <th className="CalendarDay">Mi</th>
                <th className="CalendarDay">Do</th>
                <th className="CalendarDay">Fr</th>
                <th className="CalendarDay">Sa</th>
                <th className="CalendarDay">So</th>
            </tr>
            {weeks}
            </tbody>
        </table>
    </div>
}

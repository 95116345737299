import { Component } from "react";
import MonthSheet from "./MonthSheet";

export default class Calendar extends Component {
    render() {
        const now = new Date();
        const startYear = now.getFullYear();
        let monthCounter = now.getMonth();

        const monthBlocks = [];
        for (let row = 0; row < 4; row++) {
            for (let column = 0; column < 4; column++) {
                const date = new Date(Date.UTC(startYear, monthCounter));
                monthCounter++;
                const currentYear = date.getFullYear();
                const currentMonth = date.getMonth() + 1;
                monthBlocks.push(
                    <MonthSheet key={`${currentYear}-${currentMonth}`} year={currentYear}
                                month={currentMonth}>
                    </MonthSheet>
                )
            }
        }

        return <div className="EmbeddableCalendar">
            <div className="TopBox">
                <div className="Title">Belegungsplan Ferienwohnung Haus Wößner</div>
                <br/>
                <div className="LastChanged">(letzte Aktualisierung am 02.10.2024)</div>
                <div className="LegendBox">
                    <table>
                        <tbody>
                        <tr>
                            <td className="Image Neutral">&nbsp;</td>
                            <td className="Caption">keine Angabe</td>
                            <td className="Image Free">&nbsp;</td>
                            <td className="Caption">frei</td>
                            <td className="Image Reserved">&nbsp;</td>
                            <td className="Caption">reserviert</td>
                            <td className="Image Booked">&nbsp;</td>
                            <td className="Caption">belegt</td>
                            <td className="Image Unavailable">&nbsp;</td>
                            <td className="Caption">nicht verfügbar</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            {monthBlocks}
        </div>

    }
}

import { config } from "./config";

let bookings;

export const bookingStates = {
    NONE: 0,
    MORNING: 1,
    EVENING: 2,
    MORNING_AND_EVENING: 3,
    FULL: 4,
}

function getUnixEpochByDateString(dateString) {
    const date = new Date(dateString + ' 00:00:00 UTC');
    return Math.ceil(date.getTime() / 1000);
}

function getFirstDayOfCurrentMonthDateString() {
    const now = new Date();
    const year = now.getUTCFullYear();
    const month = now.getUTCMonth() + 1;

    return `${year}-${month}-01`;
}

export async function initializeBookings() {
    if (!bookings) {
        const secondsPerDay = 24 * 60 * 60;
        const secondsOfFirstDayToConsider = getUnixEpochByDateString(getFirstDayOfCurrentMonthDateString());
        bookings = {};
        console.log('Fetching bookings...');
        const response = await fetch(config.bookingsDataUrl);
        let time;
        for (const {from, to, status} of (await response.json())) {
            if (status === 'Storniert') {
                continue;
            }
            const fromSeconds = getUnixEpochByDateString(from);
            const toSeconds = getUnixEpochByDateString(to);
            if (toSeconds < secondsOfFirstDayToConsider) {
                continue;
            }

            bookings[fromSeconds] = (bookings[fromSeconds] ?? 0) + bookingStates.EVENING;
            for (time = fromSeconds + secondsPerDay; time < toSeconds; time += secondsPerDay) {
                bookings[time] = bookingStates.FULL;
            }
            bookings[time] = (bookings[time] ?? 0) + bookingStates.MORNING;
        }
    }

    return bookings;
}

export function getBookingState(date) {
    const time = Math.ceil(date.getTime() / 1000);
    return bookings[time] ?? bookingStates.NONE;

}

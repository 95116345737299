import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { initializeBookings } from "./belegungsPlan";

window.bookings = await initializeBookings();

const entryPoint = document.getElementById("bplan");
if (entryPoint) {
    const root = createRoot(entryPoint);
    root.render(
        <StrictMode>
            <App/>
        </StrictMode>
    );
} else {
    console.log('Entry point not found');
}


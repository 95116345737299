function getCalendarWeekNumberByDate(d) {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    // Get first day of year
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));

    // Calculate full weeks to nearest Thursday
    // Return array of year and week number
    return Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
}

export function getDaysArray(year, month) {
    const date = new Date(Date.UTC(year, month - 1, 1));
    const dayOfWeek = date.getDay() || 7; // Map Sunday (0) to 7
    const startWeek = getCalendarWeekNumberByDate(date);
    let currentWeek = startWeek;

    const daysOfMonth = (Date.UTC(year, month, 1) - Date.UTC(year, month - 1, 1)) / (24 * 60 * 60 * 1000);
    const daysPerWeek = {};

    // Fill cells of first week until the first day
    daysPerWeek[currentWeek] = Array(dayOfWeek - 1).fill(null);

    for (let currentDay = 1; currentDay <= daysOfMonth; currentDay++) {
        daysPerWeek[currentWeek].push(currentDay);
        if (daysPerWeek[currentWeek].length >= 7) {
            currentWeek++;
            daysPerWeek[currentWeek] = [];
        }
    }

    daysPerWeek[currentWeek] = daysPerWeek[currentWeek].concat(Array(7 - daysPerWeek[currentWeek].length).fill(null));

    while (currentWeek - startWeek < 5) {
        currentWeek++;
        daysPerWeek[currentWeek] = Array(7).fill(null);
    }

    return daysPerWeek;
}
